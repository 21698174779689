<template>
  <!--  BEGIN NAVBAR  -->
  <div class="header-container fixed-top">
    <header class="header navbar navbar-expand-sm">
      <ul class="navbar-item theme-brand flex-row text-center">
        <li class="nav-item theme-logo">
          <router-link to="/">
            <img
              src="../../assets/img/android-chrome-192x192.png"
              class="navbar-logo"
              alt="logo"
            />
          </router-link>
        </li>
        <li class="nav-item theme-text">
          <router-link to="/" class="nav-link">GeroCare</router-link>
        </li>
      </ul>

      <!-- <ul class="navbar-item flex-row ml-md-0 ml-auto">
                        <li class="nav-item align-self-center search-animated">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search toggle-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                            <form class="form-inline search-full form-inline search" role="search">
                                <div class="search-bar">
                                    <input type="text" class="form-control search-form-control  ml-lg-auto" placeholder="Search...">
                                </div>
                            </form>
                        </li>
                    </ul> -->

      <ul class="navbar-item flex-row ml-md-auto">
        <li
          class="nav-item dropdown notification-dropdown"
          v-if="notifications.length > 0"
        >
          <a
            href="javascript:void(0);"
            class="animate__animated animate__heartBeat nav-link dropdown-toggle"
            id="notificationDropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-bell"
            >
              <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
              <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
            </svg>
            <span class="badge badge-danger text-danger"></span>
          </a>

          <div
            class="dropdown-menu position-absolute"
            aria-labelledby="notificationDropdown"
          >
            <div
              class="notification-scroll"
              style="height: 40vh; overflow: scroll"
            >
              <div
                class="dropdown-item"
                style="max-width: 60vw"
                v-for="noti in notifications"
                :key="noti.id"
              >
                <router-link
                  :to="`/incidents/view/${noti.notifiable_id}`"
                  v-if="noti.type == 'incident'"
                  @click.prevent="log()"
                >
                  <div class="media">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      v-if="noti.type == 'incident'"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-alert-circle"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="8" x2="12" y2="12"></line>
                      <line x1="12" y1="16" x2="12.01" y2="16"></line>
                    </svg>
                    <div class="media-body">
                      <div class="notification-para">{{ noti.title }}</div>
                      <small class="text-success text-right">
                        {{ formatFancyDateAgo(noti.created_at) }}
                      </small>
                    </div>
                  </div>
                </router-link>
                <hr />
              </div>
            </div>
          </div>
        </li>

        <li class="nav-item dropdown user-profile-dropdown">
          <a
            href="javascript:void(0);"
            class="nav-link dropdown-toggle user text-white"
            style="line-height: inherit;"
            id="userProfileDropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <div class="d-flex align-items-center justify-content-between">
              <span class="mr-2">
                {{ user.firstname }} {{ user.lastname }}
                <br>
                <small>{{ user.role }}</small>
              </span>
              <img :src="user.image" alt="avatar" />
            </div>
          </a>
          <div
            class="dropdown-menu position-absolute"
            aria-labelledby="userProfileDropdown"
          >
            <div class="">
              <div class="dropdown-item">
                <router-link to="/profile">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-user"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                  My Profile
                </router-link>
              </div>

              <div class="dropdown-item">
                <a class="" @click.prevent="logOut()" style="cursor: pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-log-out"
                  >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                  </svg>
                  Sign Out
                </a>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </header>
  </div>
  <!--  END NAVBAR  -->
</template>

<script>
import { mapGetters } from "vuex";
import { http } from "@/utils/http";
import { endpoints } from "@/utils/endpoints";

export default {
  name: "Navbar",

  computed: {
    ...mapGetters(["url", "user"]),
  },

  data() {
    return {
      notifications: [],
    };
  },

  mounted() {
    this.fetchNotifications();
    setInterval(() => {
      this.fetchNotifications();
    }, 60000 * 10);
  },

  methods: {
    notifyAnimate() {
      document
        .getElementById("notificationDropdown")
        .classList.remove("animate__animated", "animate__bounce");
      document
        .getElementById("notificationDropdown")
        .classList.add("animate__animated", "animate__bounce");
      setInterval(() => {
        document
          .getElementById("notificationDropdown")
          .classList.remove("animate__animated", "animate__bounce");
      }, 5000);
    },

    fetchNotifications() {
      http
        .get(endpoints.NOTIFICATIONS)
        .then((response) => {
          this.notifications = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    log() {
      console.log("clicked");
    },

    /**
     * Handle Logout
     *
     * @return void
     */
    logOut() {
      http
        .post(endpoints.LOGOUT)
        .then(() => {
          this.$store.dispatch("purge_auth");

          this.$router.push("/login");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style></style>
